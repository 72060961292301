import { uiPalette } from "@/styles/themes/colors/palettes";

export const aliasUi = {
  red: {
    main: uiPalette.red[50],
    light: uiPalette.red[20],
  },
  green: {
    main: uiPalette.green[50],
    light: uiPalette.green[20],
  },
};
type AliasThemeRedScheme = "ui.red";
type AliasThemeGreenScheme = "ui.green";
export type AliasUiRed = `${AliasThemeRedScheme}.${keyof typeof aliasUi.red}`;
export type AliasUiGreen = `${AliasThemeGreenScheme}.${keyof typeof aliasUi.green}`;

export type AliasUi = AliasUiRed | AliasUiGreen;
export type AliasUiScheme = AliasThemeRedScheme | AliasThemeGreenScheme;

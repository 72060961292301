import { themePalette, uiPalette } from "@/styles/themes/colors/palettes";

export const aliasPrimary = {
  main: themePalette.green[50],
  dark: uiPalette.green[50],
  light: uiPalette.green[20],
} as const;

export type AliasPrimaryScheme = "primary";

export type AliasPrimary = `${AliasPrimaryScheme}.${keyof typeof aliasPrimary}`;

import { gaTrackingId, gtagWrapper } from "./gtag";
import { type EventType } from "./type";

export const pageview = (url: string): void => {
  gtagWrapper()("config", gaTrackingId(), {
    page_path: url,
  });
};

export const event = (props: EventType): void => {
  const { action, eventParams } = props;
  gtagWrapper()("event", action, eventParams);
};

export const granted = (): void => {
  gtagWrapper()("consent", "update", {
    ad_storage: "granted",
    analytics_storage: "granted",
  });
};

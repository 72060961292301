import { themePalette } from "@/styles/themes/colors/palettes";

export const aliasSecondary = {
  main: themePalette.yellow[70],
  dark: themePalette.yellow[50],
  light: themePalette.yellow[30],
} as const;

export type AliasSecondaryScheme = "secondary";

export type AliasSecondary = `${AliasSecondaryScheme}.${keyof typeof aliasSecondary}`;

import { type CookieValue } from "@/hooks/useCookie";
import { datadogLogs } from "@datadog/browser-logs";
import { useEffect } from "react";
import { type CookieConsentState, useCookieConsentStore } from "./store";
import { isCookieConsentValue } from "./type";

export const useSetCookieConsentAtom = (cookieConsent: CookieValue): void => {
  const setCookieConsentAtom = useCookieConsentStore((state: CookieConsentState) => state.set);
  useEffect(() => {
    if (!isCookieConsentValue(cookieConsent)) {
      datadogLogs.logger.warn("Unintended data was in the cookieConsentCookie.", {
        cookieConsent,
      });
      return;
    }
    setCookieConsentAtom(cookieConsent);
  }, [cookieConsent, setCookieConsentAtom]);
};

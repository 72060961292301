export const toysubUrl = "https://toysub.net";
export const toysubFaqUrl = "https://hyper-wedge-523.notion.site/FAQ-7e4cfbe861ff4907a1e2bf709b596878/";
export const toysubContactUsUrl = "https://toysub.net/contact_us/";
export const toysubSurveyUrl = "https://survey.zohopublic.com/zs/PICsaP";
export const toysubTermsUrl = "https://toysub.net/kiyaku/";
export const toysubPrivacyPolicyUrl = "https://toysub.net/privacy/";
export const toranaAboutUrl = "https://torana.co.jp/about/#company-info-link";
export const toranaPlanExampleUrl = "https://toysub.net/nenrei/";
export const toranaToyShindanUrl = "https://toysub.net/shindan/";
export const toranaToyHandbookUrl = "https://toysub.net/wp-content/uploads/2024/04/toysub_chiiku_book_202404.pdf";
export const toranaCreditCardUrl = "https://toysub.channel.io/support-bots/99946";
export const toranaRecruitUrl = "https://toysub.net/recruitment";

export const toysubAutoQuitApplicationUrl = "https://toysub.channel.io/support-bots/69059";
export const toysubCSQuitApplicationUrl = "https://toysub.channel.io/support-bots/69060";
export const toysubNotEligibleForPromotionQuitApplicationUrl = "https://toysub.channel.io/support-bots/75915";
export const toysubQuitApplicationCancelUrl = "https://toysub.channel.io/support-bots/71406";
export const toysubApplicationFormContactUrl = "https://toysub.channel.io/support-bots/70812";

export const toysubCannotSighUpOrSighInUrl = "https://hyper-wedge-523.notion.site/640dc1f49c19417db21f70d115b7da3d";
export const toysubToyPartsLossUrl = "https://hyper-wedge-523.notion.site/fe432f5636ce4830bab67dd0c874eb92";
export const toysubDirtyOrCorruptedToyUrl = "https://hyper-wedge-523.notion.site/73d374542ff74d3d94ad3a4f31680f75";
export const toysubSiblingsPlanUrl = "https://hyper-wedge-523.notion.site/1-b31b2a92950b43b0a72042050176a70e";
export const toysubCvcUrl = "https://hyper-wedge-523.notion.site/13dbcb2956dc4849ad0adae71c4f46f9";

export const toysubMfaQAUrl = "https://hyper-wedge-523.notion.site/2-3f4492e66273436cacab2a8d9146e05c";

export const toysubInviteUrl = "https://invy.jp/toysub/invite?rid=ssite&rid2=mypage";
export const toyBatonApplyUrl = "https://survey.zohopublic.com/zs/HmzeEk";
export const toysubStoreUrl = "https://toysub-store.myshopify.com/";

export const toysubExchangeChanneltalkUrl =
  "https://toysub.channel.io/?utm_source=toysexchange&utm_medium=emergency&utm_campaign=addressedits&utm_id=mypage";

export const toysubCampaignInfoUrl = "https://toysub.net/times/article/otoku/";
export const toysubGifteeUrl = "https://giftee.com/items/2555";

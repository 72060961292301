import { useRouter } from "next/router";
import { useEffect } from "react";
import { pageview } from "./module";
import { useGoogleAnalyticsConsent } from "./useGoogleAnalyticsConsent";

export const useGoogleAnalyticsPageView = (): void => {
  useGoogleAnalyticsConsent();
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (path: string, { shallow }: { shallow: boolean }): void => {
      if (!shallow) {
        pageview(path);
      }
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};

import { type CookieConsentValue } from "./type";
import { createWithEqualityFn } from "zustand/traditional";

export interface CookieConsentState {
  readonly cookieState: CookieConsentValue;
  readonly set: (newValue: CookieConsentValue) => void;
}

export const useCookieConsentStore = createWithEqualityFn<CookieConsentState>()((set) => ({
  cookieState: undefined,
  set: (newValue: CookieConsentValue) => {
    set({ cookieState: newValue });
  },
}), Object.is);

export const uiPalette = {
  yellow: {
    hover: "#F9C14D",
  },
  red: {
    80: "#ED181C",
    50: "#F16063",
    20: "#FEF0F2",
  },
  green: {
    50: "#248A3D",
    20: "#66CB9F",
    10: "#DFFFE4",
  },
  brown: {
    hover: "#B2A89C",
  },
  pink: {
    50: "#FF067E",
    20: "#DF707C",
  },
} as const;

type UiRedPalette = `ui.red.${keyof typeof uiPalette.red}`;
type UiGreenPalette = `ui.green.${keyof typeof uiPalette.green}`;
type UiBrownPalette = `ui.brown.${keyof typeof uiPalette.brown}`;
type UiPinkPalette = `ui.pink.${keyof typeof uiPalette.pink}`;

export type UiPalette = UiRedPalette | UiGreenPalette | UiBrownPalette | UiPinkPalette;

import type { NextSeoProps } from "next-seo";
import { siteConfig } from "@/configs/site";

if (typeof process.env.APP_ENV !== "string") {
  throw new Error("You need to provide a App env as `APP_ENV` env variable.");
}

if (typeof process.env.APP_URL !== "string") {
  throw new Error("You need to provide a App URL as `APP_URL` env variable.");
}

const { name, description, suffix } = siteConfig;

// Doc: https://github.com/garmeeh/next-seo
export const nextSeoConfig: NextSeoProps = {
  title: name,
  titleTemplate: `%s | ${suffix}`,
  description,
  openGraph: {
    type: "website",
    url: process.env.APP_URL,
    title: name,
    description,
    locale: "ja_JP",
    site_name: name,
    images: [
      {
        url: `${process.env.APP_URL}/og-image-1200x630.png`,
        width: 1200,
        height: 630,
        alt: "Og Image",
      },
    ],
  },
  twitter: {
    handle: "@toysub_official",
    site: "@toysub_official",
    cardType: "summary_large_image",
  },
  additionalMetaTags: [
    {
      name: "viewport",
      content: "width=device-width,initial-scale=1,maximum-scale=1.0,viewport-fit=cover",
    },
    {
      name: "format-detection",
      content: "address=no,email=no,telephone=no",
    },
  ],
  additionalLinkTags: [
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: `/favicons/favicon-${process.env.APP_ENV}-32x32.png`,
    },
    {
      rel: "manifest",
      href: "/manifest.webmanifest",
      crossOrigin: "use-credentials",
    },
  ],
};

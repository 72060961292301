import {
  type CookieConsentState,
  useCookieConsentStore,
} from "@/components/fragments/modeless-dialog/cookie-consent-modeless-dialog/store";
import { useEffect } from "react";
import { granted } from "./module";

export const useGoogleAnalyticsConsent = (): void => {
  const cookieConsent = useCookieConsentStore((state: CookieConsentState) => state.cookieState);
  useEffect(() => {
    if (cookieConsent === "granted") {
      granted();
    }
  }, [cookieConsent]);
};

// Figma: https://www.figma.com/file/B702gS2UACy7jQwMS5RIOj/mypage?node-id=653%3A28013
import { type BackgroundPalette } from "./background";
import { type MonoPalette } from "./mono";
import { type ShadowPalette } from "./shadow";
import { type ThemePalette } from "./theme";
import { type UiPalette } from "./ui";

export * from "./background";
export * from "./mono";
export * from "./shadow";
export * from "./theme";
export * from "./ui";

export type ColorPalettes = BackgroundPalette | MonoPalette | ShadowPalette | ThemePalette | UiPalette;

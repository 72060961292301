import { extendTheme, theme as base } from "@chakra-ui/react";
import { fonts } from "./fonts";
import {
  aliasBackground,
  aliasError,
  aliasGray,
  aliasPrimary,
  aliasSecondary,
  aliasShadow,
  aliasTheme,
  aliasUi,
  aliasBorder,
  aliasDark,
  aliasLight,
} from "./colors/alias";
import { backgroundPalette, monoPalette, shadowPalette, themePalette, uiPalette } from "./colors/palettes";
import { aliasDarkgray } from "@/styles/themes/colors/alias/darkgray";

export const theme = extendTheme({
  fonts,
  colors: {
    ui: {
      red: {
        ...aliasUi.red,
        ...uiPalette.red,
        500: aliasUi.red.main,
        600: base.colors.red[300],
      },
      green: {
        ...aliasUi.green,
        ...uiPalette.green,
        500: aliasUi.green.main,
      },
      yellow: {
        ...uiPalette.yellow,
      },
      brown: {
        ...uiPalette.brown,
      },
      pink: {
        ...uiPalette.pink,
      },
    },
    theme: {
      yellow: {
        ...aliasTheme.yellow,
        ...themePalette.yellow,
        500: aliasTheme.yellow.main,
      },
      red: {
        ...aliasTheme.red,
        ...themePalette.red,
        500: aliasTheme.red.main,
      },
      orange: {
        ...themePalette.orange,
      },
      blue: {
        ...aliasTheme.blue,
        ...themePalette.blue,
        500: aliasTheme.blue.main,
      },
      green: {
        ...aliasTheme.green,
        ...themePalette.green,
        500: aliasTheme.green.main,
      },
      brown: {
        ...aliasTheme.brown,
      },
    },
    mono: {
      light: {
        ...monoPalette.light,
      },
      dark: {
        ...monoPalette.dark,
      },
    },
    dark: { ...aliasDark, 500: aliasDark.main },
    light: { ...aliasLight, 500: aliasLight.main },
    gray: { ...aliasGray, 500: aliasGray.main },
    darkgray: { ...aliasDarkgray, 500: aliasDarkgray.main },
    border: { ...aliasBorder },
    background: {
      ...aliasBackground,
      ...backgroundPalette,
    },
    primary: {
      ...aliasPrimary,
      500: aliasPrimary.main,
      600: aliasPrimary.main,
    },
    secondary: {
      ...aliasSecondary,
      50: themePalette.yellow[10],
      500: aliasSecondary.main,
      600: aliasSecondary.main,
    },
    error: {
      ...aliasError,
      500: aliasError.main,
      600: base.colors.red[300],
    },
  },
  shadows: {
    shadow: { ...aliasShadow, ...shadowPalette },
  },
});

import { type FC, type ReactNode } from "react";
import { Typography } from "@/components/essentials/typography";
import { UnauthedLayout } from "@/layouts";
import { Box, type BoxProps, Center } from "@chakra-ui/layout";

interface Props extends BoxProps {
  readonly title: string;
  readonly description: ReactNode;
  readonly image: ReactNode;
  readonly navigation?: ReactNode;
}

export const ErrorLayout: FC<Props> = ({ title, description, image, navigation, ...rest }) => {
  return (
    <UnauthedLayout bgColor="light.main" {...rest}>
      <Box my="1.5rem" mx="1rem">
        <Box my="1.5rem" mx="1rem">
          <Typography as="h1" variant="display3" align="center" mb="0.625rem">
            {title}
          </Typography>
          <Typography as="div" variant="text4" mb="2.25rem" color="mono.dark.54" align="center">
            {description}
          </Typography>
          <Center>{image}</Center>
        </Box>
        {navigation}
      </Box>
    </UnauthedLayout>
  );
};

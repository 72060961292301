import { safeGet } from "@/modules/utils/safe-get";
import { type IconType } from "react-icons";
import { iconAssets, type IconAssets } from "./type";
import { type IconProps } from "./Icon";

interface IconVariantValue {
  readonly icon: IconType;
  readonly props: Omit<IconProps, "as"> | undefined;
}
type IconVariant = Record<IconAssets, IconVariantValue>;

const iconVariants: IconVariant = {
  angleDown: {
    icon: iconAssets.angleDown,
    props: {
      color: "gray.main",
    },
  },
  angleLeft: {
    icon: iconAssets.angleLeft,
    props: {
      color: "gray.main",
    },
  },
  angleRight: {
    icon: iconAssets.angleRight,
    props: {
      color: "gray.main",
    },
  },
  angleUp: {
    icon: iconAssets.angleUp,
    props: {
      color: "gray.main",
    },
  },
  calendarCheck: {
    icon: iconAssets.calendarCheck,
    props: {
      color: "gray.main",
    },
  },
  creditCard: {
    icon: iconAssets.creditCard,
    props: {
      color: "gray.main",
    },
  },
  envelope: {
    icon: iconAssets.envelope,
    props: {
      color: "gray.main",
    },
  },
  penAlt: {
    icon: iconAssets.penAlt,
    props: {
      color: "gray.main",
    },
  },
  timesCircle: {
    icon: iconAssets.timesCircle,
    props: {
      color: "gray.main",
    },
  },
  walking: {
    icon: iconAssets.walking,
    props: {
      color: "gray.main",
    },
  },
  truck: {
    icon: iconAssets.truck,
    props: {
      color: "gray.main",
    },
  },
  users: {
    icon: iconAssets.users,
    props: {
      color: "gray.main",
    },
  },
  comments: {
    icon: iconAssets.comments,
    props: {
      color: "gray.main",
    },
  },
  eyeSlash: {
    icon: iconAssets.eyeSlash,
    props: {
      color: "gray.main",
    },
  },
  eye: {
    icon: iconAssets.eye,
    props: {
      color: "gray.main",
    },
  },
  userAlt: {
    icon: iconAssets.userAlt,
    props: {
      color: "gray.main",
    },
  },
  key: {
    icon: iconAssets.key,
    props: {
      color: "gray.main",
    },
  },
  lock: {
    icon: iconAssets.lock,
    props: {
      color: "gray.main",
    },
  },
  book: {
    icon: iconAssets.book,
    props: {
      color: "gray.main",
    },
  },
  signOutAlt: {
    icon: iconAssets.signOutAlt,
    props: {
      color: "gray.main",
    },
  },
  sortDown: {
    icon: iconAssets.sortDown,
    props: {
      color: "gray.main",
    },
  },
  sortUp: {
    icon: iconAssets.sortUp,
    props: {
      color: "gray.main",
    },
  },
  copy: {
    icon: iconAssets.copy,
    props: {
      color: "gray.main",
    },
  },
  piggyBank: {
    icon: iconAssets.piggyBank,
    props: {
      color: "gray.main",
    },
  },
  exclamationTriangle: {
    icon: iconAssets.exclamationTriangle,
    props: {
      color: "secondary.main",
    },
  },
  checkCircle: {
    icon: iconAssets.checkCircle,
    props: {
      color: "primary.main",
      bgColor: "light.main",
      rounded: "full",
    },
  },
  plusCircle: {
    icon: iconAssets.plusCircle,
    props: {
      color: "primary.main",
      bgColor: "light.main",
      rounded: "full",
    },
  },
  home: {
    icon: iconAssets.home,
    props: {
      color: "light.main",
      borderWidth: "0.35rem",
      borderColor: "primary.main",
      bgColor: "primary.main",
      rounded: "full",
    },
  },
  userCircle: {
    icon: iconAssets.userCircle,
    props: {
      color: "primary.main",
      bgColor: "light.main",
      rounded: "full",
    },
  },
  exclamationCircle: {
    icon: iconAssets.exclamationCircle,
    props: {
      color: "primary.main",
    },
  },
  ticket: {
    icon: iconAssets.ticket,
    props: {
      color: "gray.main",
    },
  },
};

export const injectIconVariant = (icon: IconAssets): IconVariantValue => {
  return safeGet(iconVariants, icon);
};

import { ApolloClient, type ApolloLink, from, InMemoryCache, type NormalizedCacheObject } from "@apollo/client";
import merge from "deepmerge";
import type { NextApiRequest, NextApiResponse } from "next";
import { useMemo } from "react";

interface ResolverContext {
  req?: NextApiRequest;
  res?: NextApiResponse;
}

let apolloClient: ApolloClient<NormalizedCacheObject> | null;

const createIsomorphLink = (context: ResolverContext = {}): ApolloLink => {
  if (typeof window === "undefined") {
    /* eslint-disable @typescript-eslint/no-var-requires -- conditional import */
    const { SchemaLink } = require("@apollo/client/link/schema");
    const { schema } = require("./schema");
    /* eslint-enable @typescript-eslint/no-var-requires */
    return new SchemaLink({ schema, context }) as ApolloLink;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-var-requires -- conditional import
    const { HttpLink } = require("@apollo/client/link/http");
    return new HttpLink({
      uri: process.env.APP_ENDPOINT,
      credentials: "include",
    }) as ApolloLink;
  }
};

const createApolloClient = (links: ApolloLink[]): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([...links, createIsomorphLink()]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
      },
      query: {
        fetchPolicy: "network-only",
      },
    },
  });
};

export const initializeApollo = (
  initialState: NormalizedCacheObject | null = null,
  links: ApolloLink[] = []
): ApolloClient<NormalizedCacheObject> => {
  const _apolloClient = apolloClient ?? createApolloClient(links);

  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (apolloClient !== null) apolloClient = _apolloClient;

  return _apolloClient;
};

export const useApollo = (
  initialState: NormalizedCacheObject,
  links: ApolloLink[] = []
): ApolloClient<NormalizedCacheObject> => {
  return useMemo(() => initializeApollo(initialState, links), [initialState, links]);
};

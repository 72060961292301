import { type FC, useState, useEffect } from "react";
import { HStack, Spacer } from "@chakra-ui/react";
import { FaArrowLeft, FaArrowRight, FaRedo } from "react-icons/fa";
import { useRouter } from "next/router";
import { backTestId, reloadTestId, forwardTestId } from "./const";
import { Box } from "@/components/essentials/box";
import { userMustZIndex } from "@/constants/z-index";

export const PwaFooter: FC = () => {
  const router = useRouter();

  const [isStandalone, setIsStandalone] = useState(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(display-mode: standalone)");
    setIsStandalone(mediaQuery.matches);
  }, []);

  const handleBack = (): void => {
    router.back();
  };
  const handleForward = (): void => {
    window.history.forward();
  };
  const handleReload = (): void => {
    router.reload();
  };

  return (
    <>
      {isStandalone && (
        <HStack
          position="sticky"
          bottom="0px"
          as="footer"
          zIndex={userMustZIndex}
          w="100%"
          bgColor="light.main"
          justifyContent="center"
          pt="16px"
          pb="env(safe-area-inset-bottom, 16px)"
        >
          <Box display="flex" w="100%" justifyContent="space-between" mx="5%">
            <Box display="flex" w="50%" alignItems="center" justifyContent="space-between">
              <Box>
                <button onClick={handleBack}>
                  <FaArrowLeft data-testid={backTestId} />
                </button>
              </Box>
              <Box>
                <button onClick={handleForward}>
                  <FaArrowRight data-testid={forwardTestId} />
                </button>
              </Box>

              <Box>
                <button onClick={handleReload}>
                  <FaRedo data-testid={reloadTestId} />
                </button>
              </Box>
            </Box>
            <Spacer />
          </Box>
        </HStack>
      )}
    </>
  );
};

import type { FC, ReactNode } from "react";
import { TemplateLayout } from "@/layouts/TemplateLayout";
import { type BoxProps } from "@chakra-ui/react";

interface UnauthedLayoutProps extends BoxProps {
  readonly footer?: boolean;
  readonly children: ReactNode;
}

export const UnauthedLayout: FC<UnauthedLayoutProps> = ({ children, footer, ...rest }: UnauthedLayoutProps) => {
  return (
    <TemplateLayout footer={footer} authed={false} pb="10rem" {...rest}>
      {children}
    </TemplateLayout>
  );
};

import type { FC } from "react";
import { HStack, VStack } from "@chakra-ui/react";
import { Box } from "@/components/essentials/box";
import { Typography } from "@/components/essentials/typography";
import { Divider } from "@/components/essentials/divider";
import logo from "~/assets/images/logo.svg";
import { toranaAboutUrl, toysubPrivacyPolicyUrl, toysubTermsUrl } from "@/configs/externalUrls";
import Link from "next/link";
import Image from "next/image";

export const Footer: FC = () => {
  return (
    <Box
      py="4rem"
      px={{
        base: "0rem",
        sm: "2.0rem",
        md: "2.0rem",
        lg: "2.5rem",
        xl: "2.5rem",
        "2xl": "2.5rem",
      }}
    >
      <VStack gridGap="2rem">
        <HStack
          gridGap={{
            base: "0rem",
            sm: "1rem",
            md: "1rem",
            lg: "1.5rem",
            xl: "2.5rem",
            "2xl": "2.5rem",
          }}
        >
          <Typography as={Link} href={toysubTermsUrl} target="_blank" rel="noopener norefferer" variant="display7">
            利用規約
          </Typography>
          <Divider borderColor="mono.dark.54" orientation="vertical" h="1rem" />
          <Typography
            as={Link}
            href={toysubPrivacyPolicyUrl}
            target="_blank"
            rel="noopener norefferer"
            variant="display7"
          >
            プライバシーポリシー
          </Typography>
          <Divider borderColor="mono.dark.54" variant="dark" orientation="vertical" h="1rem" />
          <Typography as={Link} href={toranaAboutUrl} target="_blank" rel="noopener norefferer" variant="display7">
            会社概要
          </Typography>
        </HStack>
        <Image alt="logo" width={134} height={56} src={logo} />
        <Typography as="p" variant="text2">
          Copyright Torana Inc. All Rights Reserved.
        </Typography>
      </VStack>
    </Box>
  );
};

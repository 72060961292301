import { Global } from "@emotion/react";
import { type ReactElement } from "react";

export const GlobalStyles = (): ReactElement<typeof Global> => (
  <Global
    styles={{
      h1: {
        fontSize: "2.25rem",
        lineHeight: "2.5rem",
        marginBlockStart: "0.5rem",
        marginBlockEnd: "0.875rem",
      },
      h2: {
        fontSize: "1.5rem",
        lineHeight: "2rem",
        marginBlockStart: "2rem",
        marginBlockEnd: "1rem",
      },
      h3: {
        fontSize: "1.25rem",
        lineHeight: "1.75rem",
        marginBlockStart: "1.75rem",
        marginBlockEnd: "0.625rem",
      },
      "h4,h5,h6": {
        fontSize: "1rem",
        lineHeight: "1.5rem",
        marginBlockStart: "1.5rem",
        marginBlockEnd: "0.5rem",
      },
      a: {
        color: "#2563EB",
      },
    }}
  />
);

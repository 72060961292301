import { Divider as ChakraDivider, type DividerProps as ChakraDividerProps } from "@chakra-ui/react";
import { useMemo, type FC } from "react";
import { dividerTestId } from "./const";
import { type DividerVariant } from "./type";
import { injectDividerVariant } from "./variant";

export interface DividerProps extends ChakraDividerProps {
  readonly variant?: DividerVariant;
}
export const Divider: FC<DividerProps> = ({ variant = "dark", ...rest }) => {
  const dividerVariant = useMemo(() => injectDividerVariant(variant), [variant]);
  return <ChakraDivider borderBottomWidth="0.0625rem" {...dividerVariant} data-testid={dividerTestId} {...rest} />;
};

import { createWithEqualityFn } from "zustand/traditional";

export interface ToastState {
  readonly message: string;
}

export interface ToastStates {
  readonly toastStates: ToastState[];
  readonly add: (newValue: ToastState) => void;
  readonly reset: () => void;
}

export const useToastStore = createWithEqualityFn<ToastStates>()((set) => ({
  toastStates: [],
  add: (newValue: ToastState) => {
    set((state: ToastStates) => ({ toastStates: [...state.toastStates, newValue] }));
  },
  reset: () => {
    set({ toastStates: [] });
  },
}), Object.is);

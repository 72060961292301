import { type GraphQLError } from "graphql/error";

// Doc: https://www.apollographql.com/docs/apollo-server/data/errors/#error-codes
export const GRAPHQL_PARSE_FAILED = "GRAPHQL_PARSE_FAILED";
export const GRAPHQL_VALIDATION_FAILED = "GRAPHQL_VALIDATION_FAILED";
export const BAD_USER_INPUT = "BAD_USER_INPUT";
export const UNAUTHENTICATED = "UNAUTHENTICATED";
export const FORBIDDEN = "FORBIDDEN";
export const PERSISTED_QUERY_NOT_FOUND = "PERSISTED_QUERY_NOT_FOUND";
export const PERSISTED_QUERY_NOT_SUPPORTED = "PERSISTED_QUERY_NOT_SUPPORTED";
export const INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR";
export const NOT_FOUND = "NOT_FOUND";

export const graphQLErrorCode = {
  GRAPHQL_PARSE_FAILED,
  GRAPHQL_VALIDATION_FAILED,
  BAD_USER_INPUT,
  UNAUTHENTICATED,
  FORBIDDEN,
  PERSISTED_QUERY_NOT_FOUND,
  PERSISTED_QUERY_NOT_SUPPORTED,
  INTERNAL_SERVER_ERROR,
  NOT_FOUND,
};

export type GraphQLErrorCode = keyof typeof graphQLErrorCode;

const createPredicate =
  (code: GraphQLErrorCode) =>
  (graphQLError: GraphQLError): boolean =>
    graphQLError.extensions.code === code;

export const isInternalServerError = createPredicate(INTERNAL_SERVER_ERROR);
export const isForbidden = createPredicate(FORBIDDEN);
export const isBadUserInput = createPredicate(BAD_USER_INPUT);

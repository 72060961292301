import { safeGet } from "@/modules/utils/safe-get";
import { type TypographyProps } from "@/components/essentials/typography";
import { type ToastProps } from "./Toast";
import { type ToastVariants } from "./type";
import { type IconProps } from "@chakra-ui/react";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { type IconType } from "react-icons";

interface ToastPropsVariantValue extends Omit<ToastProps, "variant"> {
  readonly typography: {
    readonly variant: TypographyProps["variant"];
    readonly textColor: TypographyProps["color"];
  };
}

interface ToastIconVariantValue {
  readonly color: IconProps["color"];
  readonly icon: IconType;
}

interface ToastVariantValue extends Omit<ToastProps, "variant"> {
  readonly props: ToastPropsVariantValue;
  readonly icon: ToastIconVariantValue;
}

const toastVariant = (): Record<ToastVariants, ToastVariantValue> => ({
  info: {
    props: {
      bgColor: `primary.light`,
      typography: { variant: "display6", textColor: "light.main" },
    },
    icon: {
      color: `primary.dark`,
      icon: FaCheckCircle,
    },
  },
  error: {
    props: {
      bgColor: "light.main",
      borderColor: `error.main`,
      borderWidth: "1px",
      borderRadius: "0.5rem",
      typography: { variant: "display6", textColor: `error.main` },
    },
    icon: {
      color: `error.main`,
      icon: FaExclamationCircle,
    },
  },
});

export const injectToastVariant = (variant: ToastVariants): ToastVariantValue => {
  return safeGet(toastVariant(), variant);
};

import { themePalette, uiPalette } from "@/styles/themes/colors/palettes";

export const aliasError = {
  dark: themePalette.red[50],
  main: uiPalette.red[50],
  light: uiPalette.red[20],
} as const;

export type AliasErrorScheme = "error";

export type AliasError = `${AliasErrorScheme}.${keyof typeof aliasError}`;

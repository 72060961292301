import type { FC, ReactNode } from "react";
import { TemplateLayout } from "@/layouts/TemplateLayout";
import { type BoxProps } from "@chakra-ui/react";

interface AuthedLayoutProps extends BoxProps {
  readonly footer?: boolean;
  readonly children: ReactNode;
}

export const AuthedLayout: FC<AuthedLayoutProps> = ({ children, footer, ...rest }: AuthedLayoutProps) => {
  return (
    <TemplateLayout authed footer={footer} pb="10rem" {...rest}>
      {children}
    </TemplateLayout>
  );
};

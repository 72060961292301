import { Button } from "@/components/essentials/button";
import { Typography } from "@/components/essentials/typography";
import { type TypographyBreakpointsVariant } from "@/components/essentials/typography/type";
import { cookieConsentCookieName } from "@/configs/cookies";
import { toysubPrivacyPolicyUrl } from "@/configs/externalUrls";
import { useCookie } from "@/hooks/useCookie";
import { Flex } from "@chakra-ui/react";
import { Box } from "@/components/essentials/box";
import Link from "next/link";
import { type FC, useCallback, useEffect } from "react";
import { cookieConsentTestId, grantedButtonTestId } from "./const";
import { useSetCookieConsentAtom } from "./hooks";
import { cookieConsentOptions } from "./module";
import { legalZIndex } from "@/constants/z-index";

const typographyVariant: TypographyBreakpointsVariant = {
  base: "sentence3",
  sm: "sentence3",
  md: "sentence2",
  lg: "sentence2",
  xl: "sentence2",
  "2xl": "sentence2",
} as const;

const typographyUnderline = {
  base: "underline",
  sm: "underline",
  md: "underline",
  lg: "underline",
  xl: "underline",
  "2xl": "underline",
} as const;

export const CookieConsentModelessDialog: FC = () => {
  const [cookieConsent, isLoadedCookieConsent, setCookieConsent] = useCookie(cookieConsentCookieName);
  useSetCookieConsentAtom(cookieConsent);

  const handleGranted = useCallback(() => {
    setCookieConsent("granted", cookieConsentOptions());
  }, [setCookieConsent]);

  let initScrollPosition: number;
  const scrollEvent: () => void = () => {
    if (Math.abs(initScrollPosition - window.scrollY) > 50) {
      handleGranted();
      window.removeEventListener("scroll", scrollEvent);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run first display
    initScrollPosition = window.scrollY;
    window.addEventListener("scroll", scrollEvent);
    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, []);

  if (!isLoadedCookieConsent || cookieConsent !== undefined) return null;

  return (
    <Box
      data-testid={cookieConsentTestId}
      position="fixed"
      bottom="0"
      w="100%"
      backgroundColor="theme.brown.light"
      // TODO: #1191 filterはThemeFieldに非対応なので値を直接入力している(chakura v1.8.6現在)
      filter="drop-shadow(0px -5px 24px rgba(158, 149, 138, 0.2))"
      zIndex={legalZIndex}
    >
      <Box py="12px" px="16px">
        <Flex justifyContent="space-between" alignItems="center">
          <Typography as="p" variant={typographyVariant} color="light.main" textAlign="left" mr="20px">
            当サイトでは、利便性、品質維持・向上を目的に、Cookieを使用しております。
            <br />
            閲覧を継続されることで、Cookieの利用に同意するものといたします。 詳しくは
            <Typography
              as={Link}
              href={toysubPrivacyPolicyUrl}
              variant={typographyVariant}
              color="light.main"
              textDecorationLine={typographyUnderline}
              target="_blank"
              rel="noreferrer"
            >
              こちら
            </Typography>
            をご参照ください。
          </Typography>
          <Button
            type="button"
            size="md"
            data-testid={grantedButtonTestId}
            onClick={handleGranted}
            styleVariant="brown-reverse"
          >
            OK
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

import { useMemo, type FC } from "react";
import { HStack } from "@chakra-ui/react";
import Image from "next/image";
import { headerHeight } from "@/components/fragments/header/const";
import { injectDividerVariant } from "@/components/essentials/divider/variant";
import logo from "~/assets/images/logo.svg";
import { isPrd } from "@/modules/utils/env";
import { headerZIndex } from "@/constants/z-index";

export const UnAuthedHeader: FC = () => {
  const borderProps = useMemo(() => injectDividerVariant("light"), []);
  return (
    <HStack
      position="sticky"
      top={!isPrd() ? "21px" : 0}
      m={0}
      as="header"
      zIndex={headerZIndex}
      w="100%"
      h={headerHeight}
      bgColor="light.main"
      justifyContent="center"
      borderBottomWidth="1px"
      {...borderProps}
    >
      <Image alt="logo" width="104" height="44" src={logo} />
    </HStack>
  );
};

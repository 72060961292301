import { datadogLogs, type LogsInitConfiguration } from "@datadog/browser-logs";
import { datadogRum, type RumInitConfiguration } from "@datadog/browser-rum";

export const datadogInit = (sampleRate: number = 100): void => {
  if (typeof process.env.DATADOG_BROWSER_LOGS_CLIENT_TOKEN !== "string") {
    throw new Error(
      "You need to provide a Datadog Browser Logs client token as `DATADOG_BROWSER_LOGS_CLIENT_TOKEN` env variable."
    );
  }
  if (typeof process.env.DATADOG_RUM_APPLICATION_ID !== "string") {
    throw new Error("You need to provide a Datadog RUM application ID as `DATADOG_RUM_APPLICATION_ID` env variable.");
  }
  if (typeof process.env.DATADOG_RUM_CLIENT_TOKEN !== "string") {
    throw new Error("You need to provide a Datadog RUM client token as `DATADOG_RUM_CLIENT_TOKEN` env variable.");
  }
  if (typeof process.env.APP_ENDPOINT !== "string") {
    throw new Error("You need to provide a endpoint URI as `APP_ENDPOINT` env variable.");
  }

  const site = process.env.DATADOG_SITE;
  const service = "ortega";
  const env = process.env.APP_ENV;
  const version = process.env.APP_VERSION_HASH;
  const mustBeSameConfigs: Pick<
    LogsInitConfiguration | RumInitConfiguration,
    "trackSessionAcrossSubdomains" | "useSecureSessionCookie" | "useCrossSiteSessionCookie"
  > = {
    trackSessionAcrossSubdomains: false,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: false,
  };
  let proxy;
  if (process.env.NODE_ENV === "development") {
    proxy = "http://localhost:28888/datadog/";
  }

  datadogLogs.init({
    clientToken: process.env.DATADOG_BROWSER_LOGS_CLIENT_TOKEN,
    site,
    service,
    env,
    version,
    forwardErrorsToLogs: true,
    sessionSampleRate: sampleRate,
    proxy,
    silentMultipleInit: true,
    ...mustBeSameConfigs,
  });

  datadogRum.init({
    applicationId: process.env.DATADOG_RUM_APPLICATION_ID,
    clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
    site,
    service,
    env,
    version,
    allowedTracingUrls: [process.env.APP_ENDPOINT],
    sessionSampleRate: sampleRate,
    sessionReplaySampleRate: sampleRate,
    proxy,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    enableExperimentalFeatures: ["clickmap"],
    ...mustBeSameConfigs,
  });

  datadogRum.startSessionReplayRecording();
};

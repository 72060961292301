import { setCookie, destroyCookie } from "nookies";
import { useCallback, useState } from "react";
import type * as cookie from "cookie";
import { useBoolean } from "@chakra-ui/react";
import { useLoadCookie } from "./useLoadCookie";

export type CookieValue = undefined | string;
type UseCookieReturn = [
  CookieValue,
  boolean,
  (newValue: string, options?: cookie.CookieSerializeOptions) => void,
  (options?: cookie.CookieSerializeOptions) => void
];

export const useCookie = (cookieName: string): UseCookieReturn => {
  const [isLoaded, { on: onLoadedCookie }] = useBoolean(false);
  const [value, setValue] = useState<CookieValue>(undefined);
  useLoadCookie(cookieName, setValue, onLoadedCookie);

  const updateCookie = useCallback(
    (newValue: string, options?: cookie.CookieSerializeOptions) => {
      setCookie(undefined, cookieName, newValue, options);
      setValue(newValue);
    },
    [cookieName]
  );

  const deleteCookie = useCallback(
    (options?: cookie.CookieSerializeOptions) => {
      destroyCookie(undefined, cookieName, options);
      setValue(undefined);
    },
    [cookieName]
  );

  return [value, isLoaded, updateCookie, deleteCookie];
};

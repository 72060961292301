import { type RestrictColors } from "@/styles/themes/colors/utils";
import { type ButtonProps as ChakraButtonProps, Button as ChakraButton, forwardRef } from "@chakra-ui/react";
import { type ReactNode } from "react";
import { textButtonTestId } from "./const";
import { type SizeVariant } from "./type";
import { injectSize } from "./variant";

export interface TextButtonProps extends RestrictColors<ChakraButtonProps> {
  readonly size?: SizeVariant;
  readonly children: ReactNode;
}

export const TextButton = forwardRef<TextButtonProps, "button">(
  ({ size = "md", color = "primary.main", children, ...rest }, ref) => {
    return (
      <ChakraButton
        ref={ref}
        variant="link"
        width="fit-content"
        {...injectSize(size)}
        color={color}
        _active={{ opacity: "0.7" }}
        isDisable={rest.disabled}
        data-testid={textButtonTestId}
        {...rest}
      >
        {children}
      </ChakraButton>
    );
  }
);

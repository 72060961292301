import { initializeApp } from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";
import { Analytics } from "@aws-amplify/analytics";
import { Auth } from "@aws-amplify/auth";
import { ServiceWorker } from "@aws-amplify/core";

export const notifyInit = async (): Promise<void> => {
  const isSupport = await isSupported();
  if (isSupport) {
    fcmInit();
    void registerServiceWorker();
  }

  initAnalytics();
};

const fcmInit = (): void => {
  const firebaseConfig = {
    apiKey: process.env.FCM_API_KEY ?? "",
    authDomain: process.env.FCM_AUTH_DOMAIN ?? "",
    projectId: process.env.FCM_PROJECT_ID ?? "",
    storageBucket: process.env.FCM_STORAGE_BUCKET ?? "",
    messagingSenderId: process.env.FCM_MESSAGING_SENDER_ID ?? "",
    appId: process.env.FCM_APP_ID ?? "",
  };

  getMessaging(initializeApp(firebaseConfig));
};

const initAnalytics = (): void => {
  const awsMobile = {
    aws_project_region: process.env.AA_AWS_REGION,
    aws_cognito_identity_pool_id: process.env.AA_AWS_COGNITO_IDENTIFY_POOL_ID,
    aws_cognito_region: process.env.AA_AWS_REGION,
    aws_mobile_analytics_app_id: process.env.AA_AWS_MOBILE_ANALYTICS_APP_ID,
    aws_mobile_analytics_app_region: process.env.AA_AWS_REGION,
    Analytics: {
      AWSPinpoint: {
        appId: process.env.AA_AWS_MOBILE_ANALYTICS_APP_ID,
        region: process.env.AA_AWS_REGION,
      },
    },
  };

  Auth.configure(awsMobile); // retrieve temporary AWS credentials and sign requests
  Analytics.configure(awsMobile);

  // disable auto tracking for now since we don't care about other events
  // you can always enable it later,
  // refer https://aws-amplify.github.io/docs/js/analytics#using-analytics-auto-tracking
  Analytics.autoTrack("session", {
    enable: true,
  });
};

const appPublicKey = process.env.FCM_VAPID_KEY;

const registerServiceWorker = async (): Promise<void> => {
  if (appPublicKey === undefined) {
    throw new Error("Not found FCM_VAPID_KEY in env.");
  }
  const serviceWorker = new ServiceWorker();
  try {
    await serviceWorker.register("/pwaServiceWorker.js", "/");
    // Check the current Notification permission.
    // If its denied, it's a permanent block until the user changes the permission
    const permission: NotificationPermission = await Notification.requestPermission();
    if (permission === "granted") {
      await serviceWorker.enablePush(appPublicKey);
    }
  } catch (e: unknown) {}
};

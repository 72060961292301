export function safeGet<TKey extends string | number, TObject extends Record<TKey, unknown>>(
  object: TObject,
  key: TKey
): TObject[TKey] {
  if (Object.prototype.hasOwnProperty.call(object, key)) {
    // eslint-disable-next-line security/detect-object-injection -- Safe!
    return object[key];
  }

  throw new Error(`Key ${key} is not a direct property of object`);
}

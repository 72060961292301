import {
  FaExclamationCircle,
  FaAngleDown,
  FaAngleLeft,
  FaAngleRight,
  FaAngleUp,
  FaCalendarCheck,
  FaEnvelope,
  FaPenAlt,
  FaTimesCircle,
  FaWalking,
  FaTruck,
  FaUsers,
  FaComments,
  FaEyeSlash,
  FaEye,
  FaUserAlt,
  FaKey,
  FaBook,
  FaSignOutAlt,
  FaSortDown,
  FaSortUp,
  FaExclamationTriangle,
  FaCheckCircle,
  FaPlusCircle,
  FaHome,
  FaUserCircle,
  FaCopy,
  FaLock,
  FaPiggyBank,
} from "react-icons/fa";

import { BsCreditCard } from "react-icons/bs";

import { HiTicket } from "react-icons/hi";

export const iconAssets = {
  angleDown: FaAngleDown,
  angleLeft: FaAngleLeft,
  angleRight: FaAngleRight,
  angleUp: FaAngleUp,
  calendarCheck: FaCalendarCheck,
  creditCard: BsCreditCard,
  envelope: FaEnvelope,
  penAlt: FaPenAlt,
  timesCircle: FaTimesCircle,
  walking: FaWalking,
  truck: FaTruck,
  users: FaUsers,
  comments: FaComments,
  eyeSlash: FaEyeSlash,
  eye: FaEye,
  userAlt: FaUserAlt,
  key: FaKey,
  lock: FaLock,
  book: FaBook,
  signOutAlt: FaSignOutAlt,
  sortDown: FaSortDown,
  sortUp: FaSortUp,
  copy: FaCopy,
  piggyBank: FaPiggyBank,
  // From here on down you need additional CSS.
  exclamationTriangle: FaExclamationTriangle,
  checkCircle: FaCheckCircle,
  plusCircle: FaPlusCircle,
  home: FaHome,
  userCircle: FaUserCircle,
  exclamationCircle: FaExclamationCircle,
  ticket: HiTicket,
} as const;

export type IconAssets = keyof typeof iconAssets;

import { type Predicate } from "./predicate";

export const allPass: <T>(predicates: [Predicate<T>, Predicate<T>, ...Array<Predicate<T>>]) => Predicate<T> =
  <T>(predicates: Array<Predicate<T>>) =>
  (x: T) =>
    predicates.reduce<boolean>((acc, predicate) => acc && predicate(x), true);

export const complement =
  <T>(predicate: Predicate<T>): Predicate<T> =>
  (x: T): boolean =>
    !predicate(x);

import { ApolloProvider as OriginalApolloProvider, type NormalizedCacheObject } from "@apollo/client";
import { useRouter } from "next/router";
import { type ReactNode, type FC } from "react";
import { useApollo } from "./clients";
import { createRedirectLink } from "@/apollo/auth/redirect-link";
import { createLogLink } from "./log";

export const ApolloProvider: FC<{
  readonly initialApolloState: NormalizedCacheObject;
  readonly children: ReactNode;
}> = ({ initialApolloState, children }) => {
  const router = useRouter();

  const apolloClient = useApollo(initialApolloState, [createRedirectLink(router), createLogLink()]);

  return <OriginalApolloProvider client={apolloClient}> {children}</OriginalApolloProvider>;
};

import { type ButtonProps as ChakraButtonProps } from "@chakra-ui/react";
import { type SizeVariant, type StyleVariant } from "./type";
import { injectTypographyVariant } from "@/components/essentials/typography/variant";
import { safeGet } from "@/modules/utils/safe-get";

export const sizeVariant: Record<SizeVariant, ChakraButtonProps> = {
  md: {
    py: "0.75rem",
    px: "1rem",
    width: "fit-content",
    ...injectTypographyVariant("button5"),
  },
  lg: {
    py: "0.75rem",
    ...injectTypographyVariant("button1"),
  },
} as const;

export const injectSize = (size: SizeVariant): ChakraButtonProps => {
  return safeGet(sizeVariant, size);
};

export const styleVariant: Record<StyleVariant, ChakraButtonProps> = {
  secondary: {
    colorScheme: "secondary",
    _hover: { background: "ui.yellow.hover" },
    _disabled: { cursor: "not-allowed", background: "theme.yellow.light" },
  },
  mono: {
    backgroundColor: "light.main",
    color: "gray.dark",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "border.main",
  },
  "secondary-reverse": {
    backgroundColor: "light.main",
    color: "theme.yellow.main",
  },
  "secondary-ghost": {
    colorScheme: "secondary",
    variant: "ghost",
  },
  brown: {
    backgroundColor: "theme.brown.light",
    _hover: { background: "ui.brown.hover" },
    color: "light.main",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "border.main",
  },
  "brown-reverse": {
    backgroundColor: "light.main",
    color: "theme.brown.light",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "border.main",
  },
  error: {
    colorScheme: "error",
  },
  custom: {},
} as const;

export const injectStyle = (style: StyleVariant, { disabled }: ChakraButtonProps): ChakraButtonProps => {
  if (style === "secondary") {
    return { ...styleVariant.secondary, ...(disabled === true ? { _hover: {} } : {}) };
  }

  return safeGet(styleVariant, style);
};

import { type ReactNode, useMemo } from "react";
import { Text, type TextProps, forwardRef } from "@chakra-ui/react";
import { type AliasColors } from "@/styles/themes/colors/alias";
import { type ChakraColorProps } from "@/styles/themes/colors/utils";
import { type TypographyVariant, type TypographyBreakpointsVariant } from "./type";
import { injectTypographyVariant } from "./variant";
import { type ColorPalettes } from "@/styles/themes/colors/palettes";
import { typographyTestId } from "./const";

export interface TypographyProps
  extends Omit<TextProps, ChakraColorProps | "fontSize" | "fontWeight" | "textDecoration" | "variant"> {
  readonly color?: AliasColors | ColorPalettes;
  readonly variant: TypographyVariant | TypographyBreakpointsVariant;
  readonly children: ReactNode;
  readonly target?: HTMLAnchorElement["target"];
  readonly rel?: HTMLAnchorElement["rel"];
}

export const Typography = forwardRef<TypographyProps, "div">(
  ({ children, variant, color = "mono.dark.87", ...rest }, ref) => {
    const typographyVariant = useMemo(() => injectTypographyVariant(variant), [variant]);
    return (
      <Text
        ref={ref}
        color={color}
        fontFamily="Noto Sans JP"
        {...typographyVariant}
        data-testid={typographyTestId}
        {...rest}
      >
        {children}
      </Text>
    );
  }
);

export const monoPalette = {
  light: {
    100: "#FFFFFF",
    70: "#FDFDFC",
    30: "#FBFAF7",
    12: "#FAF9F5",
  },
  dark: {
    87: "#202020",
    54: "#737270",
    26: "#B8B8B5",
    12: "#DBDAD7",
    4: "#EFEEEA",
  },
} as const;

type MonoLightPalette = `mono.light.${keyof typeof monoPalette.light}`;
type MonoDarkPalette = `mono.dark.${keyof typeof monoPalette.dark}`;

export type MonoPalette = MonoLightPalette | MonoDarkPalette;

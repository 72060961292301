// @ts-nocheck
import * as Types from '../../../../../gql/generated/graphql.gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHashFragmentsModeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetHashFragmentsModeQuery = { __typename?: 'Query', health: { __typename?: 'Health', hash?: string | null } };


export const GetHashFragmentsModeDocument = gql`
    query getHashFragmentsMode {
  health {
    hash
  }
}
    `;

/**
 * __useGetHashFragmentsModeQuery__
 *
 * To run a query within a React component, call `useGetHashFragmentsModeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHashFragmentsModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHashFragmentsModeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHashFragmentsModeQuery(baseOptions?: Apollo.QueryHookOptions<GetHashFragmentsModeQuery, GetHashFragmentsModeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHashFragmentsModeQuery, GetHashFragmentsModeQueryVariables>(GetHashFragmentsModeDocument, options);
      }
export function useGetHashFragmentsModeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHashFragmentsModeQuery, GetHashFragmentsModeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHashFragmentsModeQuery, GetHashFragmentsModeQueryVariables>(GetHashFragmentsModeDocument, options);
        }
export function useGetHashFragmentsModeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetHashFragmentsModeQuery, GetHashFragmentsModeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHashFragmentsModeQuery, GetHashFragmentsModeQueryVariables>(GetHashFragmentsModeDocument, options);
        }
export type GetHashFragmentsModeQueryHookResult = ReturnType<typeof useGetHashFragmentsModeQuery>;
export type GetHashFragmentsModeLazyQueryHookResult = ReturnType<typeof useGetHashFragmentsModeLazyQuery>;
export type GetHashFragmentsModeSuspenseQueryHookResult = ReturnType<typeof useGetHashFragmentsModeSuspenseQuery>;
export type GetHashFragmentsModeQueryResult = Apollo.QueryResult<GetHashFragmentsModeQuery, GetHashFragmentsModeQueryVariables>;
import type { FC } from "react";
import { AuthedHeader, UnAuthedHeader } from "@/components/fragments/header";
import { Footer, PwaFooter } from "@/components/fragments/footer";
import { Flex, type BoxProps } from "@chakra-ui/react";
import { Box } from "@/components/essentials/box";
import { EnvironmentBanner } from "@/components/fragments/env/environment-banner";
import { isPrd } from "@/modules/utils/env";
import { AfterRouteChangedToast } from "@/components/fragments/toast/after-route-changed-toast/";

interface TemplateLayoutProps extends BoxProps {
  readonly authed: boolean;
  readonly footer?: boolean;
  readonly children: React.ReactNode;
}

export const TemplateLayout: FC<TemplateLayoutProps> = ({ authed, footer, children, ...rest }: TemplateLayoutProps) => {
  return (
    <Flex bgColor="background.main" flexDir="column" alignItems="center" minHeight="100vh">
      {!isPrd() && <EnvironmentBanner />}
      {authed ? <AuthedHeader /> : <UnAuthedHeader />}
      <Box as="main" w={{ base: "100%", sm: "100%", md: "70%", lg: "60%", xl: "40%" }} flexGrow={1} {...rest}>
        <AfterRouteChangedToast />
        {children}
      </Box>
      {footer && (
        <Box as="footer" w="100%" flexShrink={0}>
          <Footer />
        </Box>
      )}
      <PwaFooter />
    </Flex>
  );
};

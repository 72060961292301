import { monoPalette } from "@/styles/themes/colors/palettes";

export const aliasGray = {
  dark: monoPalette.dark[54],
  main: monoPalette.dark[26],
  light: monoPalette.dark[4],
};

export type AliasGrayScheme = "gray";
export type AliasGray = `${AliasGrayScheme}.${keyof typeof aliasGray}`;

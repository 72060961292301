import { HStack, type StackProps, Icon } from "@chakra-ui/react";
import { useMemo, type FC } from "react";
import { type ToastVariants } from "./type";
import { injectToastVariant } from "./variant";
import { Typography } from "@/components/essentials/typography";
import { toastIconTestId, toastTestId, toastTypographyTestId } from "./const";

export interface ToastProps extends StackProps {
  readonly variant?: ToastVariants;
}

export const Toast: FC<ToastProps> = ({ variant = "info", children, ...props }) => {
  const { props: toastProps, icon } = useMemo(() => injectToastVariant(variant), [variant]);
  const { icon: toastIcon, color: iconColor } = icon;
  const { typography, ...toastVariant } = toastProps;

  return (
    <HStack p="1rem" {...toastVariant} data-testid={toastTestId} {...props}>
      <Icon as={toastIcon} size={32} color={iconColor} data-testid={toastIconTestId} />
      <Typography variant={typography.variant} color={typography.textColor} data-testid={toastTypographyTestId}>
        {children}
      </Typography>
    </HStack>
  );
};

import type { NextPage } from "next";
import Script from "next/script";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { DefaultSeo } from "next-seo";
import { ChakraProvider } from "@chakra-ui/react";
import { pageview } from "@/modules/utils/google-tag-manager";
import { ApolloProvider } from "@/apollo/provider";
import { nextSeoConfig } from "@/configs/next-seo";
import { GlobalStyles } from "@/styles/global";
import { datadogInit } from "@/modules/utils/datadog";
import { theme } from "@/styles/themes/extend";
// Figma: https://www.figma.com/file/3VI7MsyaTxG1UlmL7I4XQm/Futurize_mypage?node-i=357%3A16813
import "@fontsource/noto-sans-jp/400.css";
import "@fontsource/noto-sans-jp/700.css";
import "@/styles/fonts/fonts.css";
import { AppComponent } from "@/pages/__components__/AppComponent";
import { notifyInit } from "@/modules/utils/notify";

const App: NextPage<AppProps> = (props: AppProps) => {
  const { Component, pageProps } = props;

  const router = useRouter();

  // Datadog init
  datadogInit(router.pathname === "/signup/plan/[priceUuid]/[lpCode]" ? 10 : 100);

  useEffect(() => {
    router.events.on("routeChangeComplete", pageview);
    return () => {
      router.events.off("routeChangeComplete", pageview);
    };
  }, [router.events]);

  if (typeof process.env.GTM_ID !== "string") {
    throw new Error("You need to provide a Google Tag Manager ID as `GTM_ID` env variable.");
  }
  if (typeof process.env.GTM_AUTH !== "string") {
    throw new Error("You need to provide a Google Tag Manager Auth as `GTM_AUTH` env variable.");
  }
  if (typeof process.env.GTM_PREVIEW !== "string") {
    throw new Error("You need to provide a Google Tag Manager Preview as `GTM_PREVIEW` env variable.");
  }

  useEffect(() => {
    void notifyInit();
  }, []);

  return (
    <ApolloProvider initialApolloState={pageProps.initialApolloState}>
      <ChakraProvider theme={theme}>
        <DefaultSeo {...nextSeoConfig} />
        <GlobalStyles />
        <AppComponent>
          <Script
            id="gtag-base"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth=${process.env.GTM_AUTH}&gtm_preview=${process.env.GTM_PREVIEW}';f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '${process.env.GTM_ID}');
              `,
            }}
          />
          <Component {...pageProps} />
        </AppComponent>
      </ChakraProvider>
    </ApolloProvider>
  );
};

export default App;

import { Stack } from "@chakra-ui/react";
import { ErrorLayout } from "@/components/fragments/error";
import Image from "next/image";
import Link from "next/link";
import { type FC } from "react";
import { Box } from "@chakra-ui/layout";
import { Typography } from "@/components/essentials/typography";
import { toysubContactUsUrl, toysubFaqUrl } from "@/configs/externalUrls";
import { Button } from "@/components/essentials/button";
import error02 from "~/assets/images/error-02.svg";
import {
  buttonRouteContactUsTestId,
  buttonFaqTestId,
  imageTestId,
  internalServerErrorLayoutTestId,
  typographyTestId,
} from "./const";

export const InternalServerErrorLayout: FC = () => {
  return (
    <ErrorLayout
      data-testid={internalServerErrorLayoutTestId}
      title="技術的な問題が発生しています"
      description="サーバー内で問題が発生しており、このページは表示できません。お手数おかけしますが、時間を置いてからアクセスしてください。"
      image={<Image data-testid={imageTestId} src={error02} alt="error" width={255} height={255} />}
      navigation={
        <Box my="2.5rem" textAlign="center">
          <Typography data-testid={typographyTestId} variant="text2" mb="1rem" color="dark.main">
            問題が解決しない場合、こちらよりご連絡ください
          </Typography>
          <Stack>
            <Button
              as={Link}
              href={toysubContactUsUrl}
              data-testid={buttonRouteContactUsTestId}
              w="100%"
              size="lg"
              styleVariant="mono"
              target="_blank"
              rel="noreferrer"
            >
              お問い合わせ
            </Button>
            <Button
              as={Link}
              href={toysubFaqUrl}
              data-testid={buttonFaqTestId}
              w="100%"
              size="lg"
              styleVariant="mono"
              target="_blank"
              rel="noreferrer"
            >
              よくあるご質問
            </Button>
          </Stack>
        </Box>
      }
    />
  );
};

import { monoPalette } from "@/styles/themes/colors/palettes";

export const aliasBorder = {
  dark: monoPalette.dark[26],
  main: monoPalette.dark[12],
  light: monoPalette.dark[4],
};

export type AliasBorderScheme = "border";
export type AliasBorder = `${AliasBorderScheme}.${keyof typeof aliasBorder}`;

import { safeGet } from "@/modules/utils/safe-get";
import { type AliasColors } from "@/styles/themes/colors/alias";
import { type DividerVariant } from "./type";

interface DividerVariantValue {
  readonly borderColor: AliasColors;
}

const dividerVariant: Record<DividerVariant, DividerVariantValue> = {
  light: {
    borderColor: "border.light",
  },
  dark: {
    borderColor: "border.main",
  },
  error: {
    borderColor: "error.main",
  },
} as const;

export const injectDividerVariant = (variant: DividerVariant): DividerVariantValue => {
  return safeGet(dividerVariant, variant);
};

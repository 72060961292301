import { includes } from "@/modules/utils/includes";

const cookieConsentValueList = ["granted", "denied", undefined] as const;

export type CookieConsentValue = (typeof cookieConsentValueList)[number];

export const isCookieConsentValue = (arg: unknown): arg is CookieConsentValue => {
  return includes(cookieConsentValueList, arg);
};

export function assertIsCookieConsentValue(val: unknown): asserts val is CookieConsentValue {
  if (!isCookieConsentValue(val)) {
    throw new Error("Expected 'val' to be CookieConsentValue.");
  }
}

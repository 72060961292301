import { type ApolloLink } from "@apollo/client";
import { onError, type ErrorResponse } from "@apollo/client/link/error";
import { type NextRouter } from "next/router";
import { isForbidden, isInternalServerError } from "@/apollo/error-code";
import { isMfaIncorrectCode, isMfaLocked } from "@/apollo/sub-error-code";
import { pagesPath } from "@/pathpida/generated/$path";
import { localStorageKeyRedirectFrom } from "@/configs/localstorage";

export const createGraphQLErrorHandler = (router: NextRouter) =>
  function handleGraphQLErrors({ graphQLErrors }: Pick<ErrorResponse, "graphQLErrors">) {
    if (graphQLErrors === undefined) return;

    graphQLErrors.forEach((error) => {
      if (isMfaIncorrectCode(error) || isMfaLocked(error)) {
        return;
      }
      /* eslint-disable @typescript-eslint/no-floating-promises -- router.push */
      if (isForbidden(error)) {
        localStorage.setItem(localStorageKeyRedirectFrom, location.pathname);
        router.push(pagesPath.signin.$url());
      } else if (isInternalServerError(error)) {
        router.push(pagesPath.$500.$url());
      }
      /* eslint-enable @typescript-eslint/no-floating-promises */
    });
  };

export const createRedirectLink = (router: NextRouter): ApolloLink => {
  const errorHandler = createGraphQLErrorHandler(router);
  return onError(errorHandler);
};

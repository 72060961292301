export const themePalette = {
  yellow: {
    70: "#FFAC00",
    50: "#FABE42",
    30: "#FBDB97",
    10: "#FAF0DC",
  },
  red: {
    50: "#E84F64",
  },
  orange: {
    40: "#ED8936",
  },
  blue: {
    50: "#2CA4D3",
  },
  green: {
    50: "#77BD55",
  },
  brown: {
    50: "#9E958A",
  },
} as const;

type ThemeYellowPalette = `theme.yellow.${keyof typeof themePalette.yellow}`;
type ThemeRedPalette = `theme.red.${keyof typeof themePalette.red}`;
type ThemeOrangePalette = `theme.orange.${keyof typeof themePalette.orange}`;
type ThemeBluePalette = `theme.blue.${keyof typeof themePalette.blue}`;
type ThemeGreenPalette = `theme.green.${keyof typeof themePalette.green}`;
type ThemeBrownPalette = `theme.brown.${keyof typeof themePalette.brown}`;

export type ThemePalette =
  | ThemeYellowPalette
  | ThemeRedPalette
  | ThemeOrangePalette
  | ThemeBluePalette
  | ThemeGreenPalette
  | ThemeBrownPalette;

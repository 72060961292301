import { format, parse } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { isNonNullable } from "./predicate";

const defaultBackendDateFormat = "yyyy-MM-dd HH:mm:ss";

export const formatAsiaTokyoDateToString = (
  date: string | undefined | null,
  toFormat: "yyyy/MM/dd" | "yyyy年MM月dd日" | "yyyy年M月" | "yyyy年MM月" | "yyyy年" | "MM/dd（E）" | "MM月dd日",
  fromFormat: "yyyy-MM-dd HH:mm:ss" | "yyyyMMdd" = defaultBackendDateFormat,
  option?: Parameters<typeof format>[2]
): string | undefined => {
  if (date !== null && date !== undefined) {
    return format(parse(date, fromFormat, new Date()), toFormat, option);
  }
  return undefined;
};

export const formatInAsiaTokyo = (date: Date): string => {
  return formatInTimeZone(date, "Asia/Tokyo", "yyyy-MM-dd HH:mm:ss");
};

export const isValidDate = (
  date: string | undefined | null,
  dateFormat: "yyyyMMdd" | "yyyy-MM-dd HH:mm:ss"
): boolean => {
  if (!isNonNullable(date)) {
    return false;
  }

  const parseDate = parse(date, dateFormat, new Date());

  return !isNaN(parseDate.getTime());
};

import { parseCookies } from "nookies";
import { type Dispatch, type SetStateAction, useEffect } from "react";
import { type CookieValue } from ".";

export const useLoadCookie = (
  cookieName: string,
  setValue: Dispatch<SetStateAction<CookieValue>>,
  onLoadedCookie: () => void
): void => {
  // ClientでCookieを読み込むためにuseEffectを使用
  useEffect(() => {
    setValue(Object.entries(parseCookies(undefined)).find((cookie) => cookie[0] === cookieName)?.[1]);
    onLoadedCookie();
  }, [cookieName, onLoadedCookie, setValue]);
};

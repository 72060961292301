import { themePalette } from "@/styles/themes/colors/palettes";

export const aliasTheme = {
  red: { main: themePalette.red[50] },
  green: { main: themePalette.green[50] },
  yellow: { main: themePalette.yellow[70], light: themePalette.yellow[30], lightest: themePalette.yellow[10] },
  blue: { main: themePalette.blue[50] },
  brown: { light: themePalette.brown[50] },
};
type AliasThemeRedScheme = "theme.red";
type AliasThemeGreenScheme = "theme.green";
type AliasThemeYellowScheme = "theme.yellow";
type AliasThemeBlueScheme = "theme.blue";
type AliasThemeBrownScheme = "theme.brown";

export type AliasThemeRed = `${AliasThemeRedScheme}.${keyof typeof aliasTheme.red}`;
export type AliasThemeGreen = `${AliasThemeGreenScheme}.${keyof typeof aliasTheme.green}`;
export type AliasThemeYellow = `${AliasThemeYellowScheme}.${keyof typeof aliasTheme.yellow}`;
export type AliasThemeBlue = `${AliasThemeBlueScheme}.${keyof typeof aliasTheme.blue}`;
export type AliasThemeBrown = `${AliasThemeBrownScheme}.${keyof typeof aliasTheme.blue}`;

export type AliasTheme = AliasThemeRed | AliasThemeGreen | AliasThemeYellow | AliasThemeBlue | AliasThemeBrown;
export type AliasThemeScheme =
  | AliasThemeRedScheme
  | AliasThemeGreenScheme
  | AliasThemeYellowScheme
  | AliasThemeBlueScheme
  | AliasThemeBrownScheme;

import { InternalServerErrorLayout } from "@/components/fragments/error/internal-server-error-layout";
import React, { type ErrorInfo, type ReactNode } from "react";
import { datadogLogs } from "@datadog/browser-logs";

interface ErrorBoundaryProps {
  readonly children: ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state: { hasError: boolean };
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    datadogLogs.logger.error("ErrorBoundary componentDidCatch", { error, errorInfo });
  }

  render(): ReactNode {
    return this.state.hasError ? <InternalServerErrorLayout /> : this.props.children;
  }
}

export default ErrorBoundary;

import { Toast, useToast } from "@/components/essentials/toast";
import { type ToastStates, useToastStore } from "@/components/fragments/toast/after-route-changed-toast/store";
import { useRouter } from "next/router";
import { type FC, useCallback, useEffect } from "react";

export const useShowToast = (): ((message: string) => void) => {
  const toast = useToast();

  return useCallback(
    (message: string): void => {
      toast({
        position: "top",
        containerStyle: { width: "100%" },
        render: () => <Toast variant="info">{message}</Toast>,
      });
    },
    [toast]
  );
};

export const AfterRouteChangedToast: FC = () => {
  const afterRouteChangedToasts = useToastStore((state: ToastStates) => state.toastStates);
  const resetAfterRouteChangedToasts = useToastStore((state: ToastStates) => state.reset);
  const showToast = useShowToast();
  const router = useRouter();

  const handleRouteChange = useCallback(() => {
    afterRouteChangedToasts.forEach((toastProp) => {
      showToast(toastProp.message);
    });
    resetAfterRouteChangedToasts();
  }, [resetAfterRouteChangedToasts, afterRouteChangedToasts, showToast]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- workaround for snapshot test
    if (router?.events?.on === undefined) return;
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router, handleRouteChange]);

  return null;
};

import { useCallback, useMemo, type FC } from "react";
import { HStack, Spacer } from "@chakra-ui/react";
import Image from "next/image";
import { Typography } from "@/components/essentials/typography";
import { headerHeight } from "@/components/fragments/header/const";
import { injectDividerVariant } from "@/components/essentials/divider/variant";
import Link from "next/link";
import logo from "~/assets/images/logo.svg";
import { pagesPath } from "@/pathpida/generated/$path";
import { useRouter } from "next/router";
import { isPrd } from "@/modules/utils/env";
import { headerZIndex } from "@/constants/z-index";

export const AuthedHeader: FC = () => {
  const router = useRouter();
  const onClickLogo = useCallback(async () => await router.push(pagesPath.$url()), [router]);
  const borderProps = useMemo(() => injectDividerVariant("light"), []);
  return (
    <HStack
      w="100%"
      h={headerHeight}
      bgColor="light.main"
      zIndex={headerZIndex}
      position="sticky"
      top={!isPrd() ? "21px" : 0}
      justifyContent="space-between"
      px={{
        base: "1.5rem",
        sm: "2.0rem",
        md: "2.5rem",
        lg: "3.75rem",
        xl: "3.75rem",
        "2xl": "3.75rem",
      }}
      borderBottomWidth="1px"
      {...borderProps}
    >
      {/* logo */}
      <Image
        alt="logo"
        width="104"
        height="48"
        src={logo}
        style={{ cursor: "pointer" }}
        onClick={() => {
          void onClickLogo();
        }}
      />
      <Spacer />
      <HStack gridGap="2rem">
        <Typography
          as={Link}
          href={pagesPath.$url()}
          variant={{
            base: "display6",
            sm: "display6",
            md: "display6",
            lg: "display2",
            xl: "display2",
            "2xl": "display2",
          }}
          color="gray.dark"
          cursor="pointer"
          _hover={{ opacity: 0.7 }}
        >
          ホーム
        </Typography>
        <Typography
          as={Link}
          href={pagesPath.settings.$url()}
          variant={{
            base: "display6",
            sm: "display6",
            md: "display6",
            lg: "display2",
            xl: "display2",
            "2xl": "display2",
          }}
          color="gray.dark"
          cursor="pointer"
          _hover={{ opacity: 0.7 }}
        >
          設定
        </Typography>
      </HStack>
    </HStack>
  );
};

import { isBadUserInput, isForbidden } from "./error-code";
import { type GraphQLError } from "graphql/error";
import { allPass } from "@/modules/utils/logic";

// Doc: https://github.com/torana-us/ortega/blob/master/apps/api/app/Enum/SubErrorCodeEnum.php
export const FAILED_TO_SIGN_IN = "FAILED_TO_SIGN_IN";
export const LOCKED = "LOCKED";
export const INVALID_TOKEN = "INVALID_TOKEN";
export const INVALID_PASSWORD_INPUT = "INVALID_PASSWORD_INPUT";
export const NO_DETAILED_INFORMATION = "NO_DETAILED_INFORMATION";
export const MFA_ALREADY_REGISTERED = "MFA_ALREADY_REGISTERED";
export const MFA_INCORRECT_CODE = "MFA_INCORRECT_CODE";
export const MFA_VERIFY_PASSWORD_EXPIRED = "MFA_VERIFY_PASSWORD_EXPIRED";
export const MFA_LOCKED = "MFA_LOCKED";
export const INVALID_RECOVERY_CODE = "INVALID_RECOVERY_CODE";

export const graphQLSubErrorCode = {
  FAILED_TO_SIGN_IN,
  LOCKED,
  INVALID_TOKEN,
  INVALID_PASSWORD_INPUT,
  NO_DETAILED_INFORMATION,
  MFA_ALREADY_REGISTERED,
  MFA_INCORRECT_CODE,
  MFA_VERIFY_PASSWORD_EXPIRED,
  MFA_LOCKED,
  INVALID_RECOVERY_CODE,
};

export type GraphQLSubErrorCode = keyof typeof graphQLSubErrorCode;

export const createPredicate =
  (subCode: GraphQLSubErrorCode) =>
  (graphQLError: GraphQLError): boolean =>
    graphQLError.extensions.subCode === subCode;

const is = createPredicate;

export const isInvalidToken = allPass<GraphQLError>([isBadUserInput, is(INVALID_TOKEN)]);
export const isMfaIncorrectCode = allPass<GraphQLError>([isForbidden, is(MFA_INCORRECT_CODE)]);
export const isMfaLocked = allPass<GraphQLError>([isForbidden, is(MFA_LOCKED)]);

export const pageview = (url: string): void => {
  if (window.dataLayer === undefined) return;

  window.dataLayer.push({
    event: "pageview",
    page: url,
  });
};

type GAEventParam = Record<string, string | number | undefined>;

export const event = (event: string, params?: GAEventParam): void => {
  if (window.dataLayer === undefined) return;

  window.dataLayer.push({
    event,
    ...params,
  });
};

import { type ReactNode, type ForwardedRef, useMemo } from "react";
import { type RestrictColors } from "@/styles/themes/colors/utils";
import { Button as ChakraButton, type ButtonProps as ChakraButtonProps, forwardRef } from "@chakra-ui/react";
import { type SizeVariant, type StyleVariant } from "./type";
import { injectSize, injectStyle } from "./variant";
import { buttonTestId } from "./const";

export interface ButtonProps extends RestrictColors<ChakraButtonProps> {
  readonly size?: SizeVariant;
  readonly styleVariant?: StyleVariant;
  readonly children: ReactNode;
}

export const Button = forwardRef<ButtonProps, "button">(
  ({ size = "md", styleVariant = "secondary", children, ...rest }, ref: ForwardedRef<HTMLButtonElement>) => {
    const buttonSize = useMemo(() => injectSize(size), [size]);
    const buttonStyle = useMemo(() => injectStyle(styleVariant, rest), [styleVariant, rest]);

    return (
      <ChakraButton
        ref={ref}
        borderRadius="0.5rem"
        transition="background 0.3s ease"
        {...buttonSize}
        {...buttonStyle}
        isDisabled={rest.disabled}
        data-testid={buttonTestId}
        {...rest}
      >
        {children}
      </ChakraButton>
    );
  }
);

import { type ReactNode, type ForwardedRef } from "react";
import { Box as ChakraBox, type BoxProps as ChakraBoxProps, forwardRef } from "@chakra-ui/react";

export interface BoxProps extends ChakraBoxProps {
  readonly children?: ReactNode;
}

export const Box = forwardRef<BoxProps, "div">(({ children, ...rest }, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <ChakraBox ref={ref} {...rest}>
      {children}
    </ChakraBox>
  );
});

export interface SiteConfig {
  readonly name: string;
  readonly suffix: string;
  readonly shortName: string;
  readonly description: string;
}

export const siteConfig: SiteConfig = {
  name: "トイサブ！",
  suffix: "Toysub!",
  shortName: "Toysub！",
  description:
    "トイサブ！では上質な知育玩具・おもちゃを定額でレンタルいただけます。データに基づき親子で楽しむ最適な玩具をお届けします。赤ちゃん・乳児から幼児までをサポートし、月齢に合わせていつも最適なおもちゃがお手元にある環境を実現するおもちゃのサブスクリプションサービスです。",
};

import { type ErrorResponse } from "@apollo/client/link/error";
import { isInternalServerError } from "@/apollo/error-code";
import { datadogLogs } from "@datadog/browser-logs";

export const createGraphQLErrorHandler = () =>
  function handleGraphQLErrors({ graphQLErrors }: Pick<ErrorResponse, "graphQLErrors">) {
    if (graphQLErrors === undefined) return;

    graphQLErrors.forEach((error) => {
      if (isInternalServerError(error)) {
        datadogLogs.logger.error("graphQL Internal server error", error);
      }
    });
  };

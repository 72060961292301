import { useMemo, type FC } from "react";
import { Icon as ChakraIcon, type IconProps as ChakraIconProps } from "@chakra-ui/react";
import { type IconAssets } from "./type";
import { injectIconVariant } from "./variant";
import { iconTestId } from "./const";

export interface IconProps extends Omit<ChakraIconProps, "css" | "as"> {
  as: IconAssets;
}
export const Icon: FC<IconProps> = ({ as, boxSize = 4, ...rest }) => {
  const { icon, props } = useMemo(() => injectIconVariant(as), [as]);
  return <ChakraIcon as={icon} boxSize={boxSize} data-testid={iconTestId} {...props} {...rest} />;
};

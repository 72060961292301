import { type FC } from "react";
import { useGoogleAnalyticsPageView } from "@/components/fragments/google-analytics";
import { CookieConsentModelessDialog } from "@/components/fragments/modeless-dialog";
import ErrorBoundary from "./ErrorBoundary";

interface Props {
  children: React.ReactNode;
}

export const AppComponent: FC<Props> = ({ children }) => {
  useGoogleAnalyticsPageView();
  return (
    <ErrorBoundary>
      {children}
      <CookieConsentModelessDialog />
    </ErrorBoundary>
  );
};

import type { OptionalQuery as OptionalQuery_1rqvalq } from '../../pages/plans/detail/[shipmentId]/index.page';

export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "$500": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/500' as const, hash: url?.hash })
  },
  "confirm": {
    "change_email": {
      _token: (token: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/confirm/change-email/[token]' as const, query: { token }, hash: url?.hash })
      })
    },
    "password_reset": {
      _token: (token: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/confirm/password-reset/[token]' as const, query: { token }, hash: url?.hash })
      })
    },
    "signup": {
      _token: (token: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/confirm/signup/[token]' as const, query: { token }, hash: url?.hash })
      })
    }
  },
  "exchange": {
    _shipmentId: (shipmentId: string | number) => ({
      "form": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/exchange/[shipmentId]/form' as const, query: { shipmentId }, hash: url?.hash })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/exchange/[shipmentId]' as const, query: { shipmentId }, hash: url?.hash })
    }),
    "pre_shipping": {
      _shipmentId: (shipmentId: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/exchange/pre-shipping/[shipmentId]' as const, query: { shipmentId }, hash: url?.hash })
      })
    }
  },
  "manifest_webmanifest": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/manifest.webmanifest' as const, hash: url?.hash })
  },
  "plans": {
    "detail": {
      _shipmentId: (shipmentId: string | number) => ({
        $url: (url?: { query?: OptionalQuery_1rqvalq | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/plans/detail/[shipmentId]' as const, query: { shipmentId, ...url?.query }, hash: url?.hash })
      })
    },
    "history": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/plans/history' as const, hash: url?.hash })
    }
  },
  "purchase": {
    _shipmentId: (shipmentId: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/purchase/[shipmentId]' as const, query: { shipmentId }, hash: url?.hash })
    })
  },
  "questionnaire": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/questionnaire' as const, hash: url?.hash })
  },
  "quit": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/quit' as const, hash: url?.hash })
  },
  "settings": {
    "account": {
      "change": {
        "email": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/settings/account/change/email' as const, hash: url?.hash })
        },
        "password": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/settings/account/change/password' as const, hash: url?.hash })
        }
      },
      "mfa": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/settings/account/mfa' as const, hash: url?.hash })
      },
      "toy_coupon": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/settings/account/toy-coupon' as const, hash: url?.hash })
      }
    },
    "billing": {
      "info": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/settings/billing/info' as const, hash: url?.hash })
      }
    },
    "contract": {
      _contractId: (contractId: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/settings/contract/[contractId]' as const, query: { contractId }, hash: url?.hash })
      })
    },
    "shipment": {
      _shipmentId: (shipmentId: string | number) => ({
        "info": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/settings/shipment/[shipmentId]/info' as const, query: { shipmentId }, hash: url?.hash })
        }
      }),
      "new": {
        _from: (from: string | number) => ({
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/settings/shipment/new/[from]' as const, query: { from }, hash: url?.hash })
        })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/settings/shipment' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/settings' as const, hash: url?.hash })
  },
  "signin": {
    "reset": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signin/reset' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signin' as const, hash: url?.hash })
  },
  "signup": {
    "plan": {
      _priceUuid: (priceUuid: string | number) => ({
        _lpCode: (lpCode: string | number) => ({
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signup/plan/[priceUuid]/[lpCode]' as const, query: { priceUuid, lpCode }, hash: url?.hash })
        }),
        "member": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signup/plan/[priceUuid]/member' as const, query: { priceUuid }, hash: url?.hash })
        },
        "thanks": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signup/plan/[priceUuid]/thanks' as const, query: { priceUuid }, hash: url?.hash })
        },
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signup/plan/[priceUuid]' as const, query: { priceUuid }, hash: url?.hash })
      })
    },
    "questionnaire": {
      _customerId: (customerId: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signup/questionnaire/[customerId]' as const, query: { customerId }, hash: url?.hash })
      })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signup' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash })
};

export type PagesPath = typeof pagesPath;

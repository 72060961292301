export const gtagWrapper = (): Gtag.Gtag => {
  if (typeof window.gtag !== "function") {
    throw new Error("You need to wait loaded window.gtag before call gtag");
  }

  return window.gtag;
};

export const gaTrackingId = (): string => {
  if (typeof process.env.GA_TRACKING_ID !== "string") {
    throw new Error("You need to provide a GoogleAnalytics tracking ID as `GA_TRACKING_ID` env variable.");
  }

  return process.env.GA_TRACKING_ID;
};

import { safeGet } from "@/modules/utils/safe-get";
import { type TextProps } from "@chakra-ui/react";
import { type TypographyBreakpointsVariant, type TypographyVariant } from "./type";

export interface TypographyVariantValue {
  readonly fontSize: TextProps["fontSize"];
  readonly fontWeight: TextProps["fontWeight"];
  readonly textDecoration: TextProps["textDecoration"];
  readonly lineHeight: TextProps["lineHeight"];
}

export const typographyValiant: Record<TypographyVariant, TypographyVariantValue> = {
  display1: {
    fontSize: "1.25rem",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  display2: {
    fontSize: "1.125rem",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  display3: {
    fontSize: "1.0625rem",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  display4: {
    fontSize: "1rem",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  display5: {
    fontSize: "0.9375rem",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  display6: {
    fontSize: "0.875rem",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  display7: {
    fontSize: "0.8125rem",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  display8: {
    fontSize: "0.625rem",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  text1: {
    fontSize: "0.9375rem",
    fontWeight: "400",
    textDecoration: "none",
    lineHeight: undefined,
  },
  text2: {
    fontSize: "0.875rem",
    fontWeight: "400",
    textDecoration: "none",
    lineHeight: undefined,
  },
  "text2-bold": {
    fontSize: "0.875rem",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  text3: {
    fontSize: "0.8125rem",
    fontWeight: "400",
    textDecoration: "none",
    lineHeight: undefined,
  },
  text4: {
    fontSize: "0.75rem",
    fontWeight: "400",
    textDecoration: "none",
    lineHeight: undefined,
  },
  "text4-bold": {
    fontSize: "0.75rem",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  text5: {
    fontSize: "0.625rem",
    fontWeight: "400",
    textDecoration: "none",
    lineHeight: undefined,
  },
  sentence1: {
    fontSize: "0.875rem",
    fontWeight: "400",
    textDecoration: "none",
    lineHeight: "180%",
  },
  sentence2: {
    fontSize: "0.8125rem",
    fontWeight: "400",
    textDecoration: "none",
    lineHeight: "180%",
  },
  "sentence2-bold": {
    fontSize: "0.8125rem",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: "180%",
  },
  sentence3: {
    fontSize: "0.75rem",
    fontWeight: "400",
    textDecoration: "none",
    lineHeight: "180%",
  },
  "sentence3-bold": {
    fontSize: "0.75rem",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: "180%",
  },
  button1: {
    fontSize: "1rem",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
  button2: {
    fontSize: "1rem",
    fontWeight: "400",
    textDecoration: "underline",
    lineHeight: undefined,
  },
  button3: {
    fontSize: "0.875rem",
    fontWeight: "400",
    textDecoration: "none",
    lineHeight: undefined,
  },
  button4: {
    fontSize: "0.875rem",
    fontWeight: "400",
    textDecoration: "underline",
    lineHeight: undefined,
  },
  button5: {
    fontSize: "0.8125rem",
    fontWeight: "700",
    textDecoration: "none",
    lineHeight: undefined,
  },
};

const switchVariant = (variant: TypographyVariant): TypographyVariantValue => {
  return safeGet(typographyValiant, variant);
};
export const injectTypographyVariant = (
  variant: TypographyVariant | TypographyBreakpointsVariant
): TypographyVariantValue => {
  if (typeof variant === "string") {
    return switchVariant(variant);
  } else {
    return {
      fontSize: {
        base: switchVariant(variant.base).fontSize as string,
        sm: switchVariant(variant.sm).fontSize as string,
        md: switchVariant(variant.md).fontSize as string,
        lg: switchVariant(variant.lg).fontSize as string,
        xl: switchVariant(variant.xl).fontSize as string,
        "2xl": switchVariant(variant["2xl"]).fontSize as string,
      },
      fontWeight: {
        base: switchVariant(variant.base).fontWeight as string,
        sm: switchVariant(variant.sm).fontWeight as string,
        md: switchVariant(variant.md).fontWeight as string,
        lg: switchVariant(variant.lg).fontWeight as string,
        xl: switchVariant(variant.xl).fontWeight as string,
        "2xl": switchVariant(variant["2xl"]).fontWeight as string,
      },
      textDecoration: {
        base: switchVariant(variant.base).textDecoration as string,
        sm: switchVariant(variant.sm).textDecoration as string,
        md: switchVariant(variant.md).textDecoration as string,
        lg: switchVariant(variant.lg).textDecoration as string,
        xl: switchVariant(variant.xl).textDecoration as string,
        "2xl": switchVariant(variant["2xl"]).textDecoration as string,
      },
      lineHeight: {
        base: switchVariant(variant.base).lineHeight as string,
        sm: switchVariant(variant.sm).lineHeight as string,
        md: switchVariant(variant.md).lineHeight as string,
        lg: switchVariant(variant.lg).lineHeight as string,
        xl: switchVariant(variant.xl).lineHeight as string,
        "2xl": switchVariant(variant["2xl"]).lineHeight as string,
      },
    };
  }
};

import { Typography } from "@/components/essentials/typography";
import { isStg } from "@/modules/utils/env";
import { Flex } from "@chakra-ui/layout";
import { useCallback, type FC } from "react";
import { useGetHashFragmentsModeQuery } from "./__gql__/get-hash.gen";
import { Icon } from "@/components/essentials/icon";
import { useToast } from "@/components/essentials/toast/hooks";
import { Toast } from "@/components/essentials/toast/Toast";
import { useRouter } from "next/router";
import { formatInAsiaTokyo } from "@/modules/utils/date";
import {
  environmentBannerBackendHashTestId,
  environmentBannerCopyTestId,
  environmentBannerFrontendHashTestId,
  environmentBannerTestId,
} from "./const";
import { developZIndex } from "@/constants/z-index";

const viewHashLength = 7;

export const EnvironmentBanner: FC = () => {
  const router = useRouter();
  const resultGetHash = useGetHashFragmentsModeQuery({ fetchPolicy: "cache-first" });
  const toast = useToast();

  const FHash = process.env.APP_VERSION_HASH?.slice(0, viewHashLength) ?? "";
  const BHash = resultGetHash.data?.health.hash?.slice(0, viewHashLength) ?? "";

  const copyToClipboard = useCallback((): void => {
    const text = `- Front: ${FHash} (https://github.com/torana-us/ortega/tree/${FHash}/apps/frontend)
- Back: ${BHash} (https://github.com/torana-us/ortega/tree/${BHash}/apps/api)
- UA: ${navigator.userAgent}
- Date: ${formatInAsiaTokyo(new Date())}
- Path: ${process.env.APP_URL as string}${router.pathname}`;

    navigator.clipboard.writeText(text).then(
      () => {
        toast({
          render: () => <Toast variant="info">コピーしました</Toast>,
        });
      },
      () => {
        toast({
          render: () => <Toast variant="error">コピーに失敗しました</Toast>,
        });
      }
    );
  }, [FHash, BHash, router.pathname, toast]);

  return (
    <Flex
      data-testid={environmentBannerTestId}
      justify="space-evenly"
      alignItems="center"
      position="sticky"
      top="0"
      zIndex={developZIndex}
      w="100%"
      bg={isStg() ? "#0000ff" : "#ff0000"}
    >
      <Typography variant="text2" color="mono.light.100">
        THIS IS {process.env.APP_ENV?.toUpperCase()}
      </Typography>
      <Flex justify="space-evenly" minW={130}>
        <Typography
          variant="text4"
          color="mono.light.100"
          marginRight={3}
          data-testid={environmentBannerFrontendHashTestId}
        >
          F: {FHash}
        </Typography>
        <Typography
          variant="text4"
          color="mono.light.100"
          marginRight={3}
          data-testid={environmentBannerBackendHashTestId}
        >
          B: {BHash}
        </Typography>
        <Icon
          as="copy"
          color="mono.light.100"
          onClick={copyToClipboard}
          data-testid={environmentBannerCopyTestId}
          cursor="pointer"
        />
      </Flex>
    </Flex>
  );
};

import { type SizeVariant } from "./type";
import { type ButtonProps as ChakraButtonProps } from "@chakra-ui/react";
import { injectTypographyVariant } from "@/components/essentials/typography/variant";

export const sizeVariant: Record<SizeVariant, ChakraButtonProps> = {
  sm: injectTypographyVariant("button4"),
  md: injectTypographyVariant("button2"),
};

export const injectSize = (size: SizeVariant): ChakraButtonProps => {
  switch (size) {
    case "sm":
      return sizeVariant.sm;
    case "md":
      return sizeVariant.md;
    default:
      throw new Error("Invalid size variant");
  }
};

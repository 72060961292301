export const isPrd = (): boolean => {
  return process.env.APP_ENV === "production";
};

export const isStg = (): boolean => {
  return process.env.APP_ENV === "staging";
};

export const isDevelopment = (): boolean => {
  return process.env.APP_ENV === "development";
};

export const isQa = (): boolean => {
  return process.env.APP_ENV === "qa";
};

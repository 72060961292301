import { type AliasDarkgray, type AliasDarkgrayScheme } from "@/styles/themes/colors/alias/darkgray";
import { type AliasBackground, type AliasBackgroundScheme } from "./background";
import { type AliasBorder, type AliasBorderScheme } from "./border";
import { type AliasError, type AliasErrorScheme } from "./error";
import { type AliasDark, type AliasDarkScheme } from "./dark";
import { type AliasLight, type AliasLightScheme } from "./light";
import { type AliasGray, type AliasGrayScheme } from "./gray";
import { type AliasPrimary, type AliasPrimaryScheme } from "./primary";
import { type AliasSecondary, type AliasSecondaryScheme } from "./secondary";
import { type AliasTheme, type AliasThemeScheme } from "./theme";
import { type AliasShadow, type AliasShadowScheme } from "./shadow";
import { type AliasUi, type AliasUiScheme } from "./ui";

export * from "./background";
export * from "./border";
export * from "./error";
export * from "./dark";
export * from "./light";
export * from "./gray";
export * from "./primary";
export * from "./secondary";
export * from "./theme";
export * from "./ui";
export * from "./shadow";

export type AliasColors =
  | AliasBackground
  | AliasBorder
  | AliasError
  | AliasDark
  | AliasLight
  | AliasDarkgray
  | AliasGray
  | AliasPrimary
  | AliasSecondary
  | AliasTheme
  | AliasShadow
  | AliasUi;
export type AliasColorScheme =
  | AliasBackgroundScheme
  | AliasBorderScheme
  | AliasErrorScheme
  | AliasDarkScheme
  | AliasLightScheme
  | AliasDarkgrayScheme
  | AliasGrayScheme
  | AliasPrimaryScheme
  | AliasSecondaryScheme
  | AliasThemeScheme
  | AliasShadowScheme
  | AliasUiScheme;
